// ::-webkit-scrollbar {
//   height: 2px;
//   width: 8px;
// }

// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px var(--watermelon);
//   background: var(--watermelon);
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: var(--white);
// }

// ::-webkit-scrollbar:hover {
//   height: 10px;
//   width: 8px;
// }

// div:hover::-webkit-scrollbar-corner {
//   width: 5px;
// }

// ::-webkit-scrollbar-track-piece {
//   background-color: var(--wash-me);
// }

// ::-webkit-scrollbar-thumb:vertical {
//   background-color: var(--watermelon);
// }

// ::-webkit-scrollbar-thumb:hover {
//   cursor: pointer;
//   background-color: orange;
// }

/* Hide scrollbar for Chrome, Safari and Opera */
.remove-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.remove-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scroll {
  overflow: hidden;
}

.auto-scroll {
  overflow: auto;
}

.has-scroll {
  overflow: scroll;
}

.visible-scroll {
  overflow: visible;
}

.no-x-scroll {
  overflow-x: hidden;
}

.has-x-scroll {
  overflow-x: scroll;
}

.no-y-scroll {
  overflow-y: hidden;
}

.has-y-scroll {
  overflow-y: scroll;
}

.auto-x-scroll {
  overflow-x: auto;
}

.auto-y-scroll {
  overflow-y: auto;
}
