* {
  font-family: 'Montserrat';
}

@media print {
  div {
    break-inside: avoid;
  }

  * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
}

/* #root {
  min-height: 100vh;
  min-width: 100vw;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
} */
