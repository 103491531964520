.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
