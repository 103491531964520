$transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);

.side-menu-transition {
  &-enter .sidebar-list {
    transform: translateX(-100%);
  }
  &-enter-active .sidebar-list {
    transform: translateX(0);
    transition: $transition;
  }
  &-exit .sidebar-list {
    transform: translateX(0);
  }
  &-exit-active .sidebar-list {
    transform: translateX(-100%);
    transition: $transition;
  }
}
