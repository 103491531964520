.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
