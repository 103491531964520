@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@import url('https://fonts.googleapis.com/css?family=Nunito|Raleway:400,400i,600|Inter|Roboto|Montserrat:ital,wght@0,300;0,400;1,400;0,500;0,600,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'AvenirLTStd';
  src: url('../../public/fonts/AvenirLTStd-Light.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-BookItalic';
  src: url('../../public/fonts/Gotham-BookItalic.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../../public/fonts/Gotham-Book.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../../public/fonts/Gotham-Medium.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../../public/fonts/GothamBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Light';
  src: url('../../public/fonts/GothamLight.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('../../public/fonts/PlayfairDisplay-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../../public/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../public/fonts/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}

* {
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}
