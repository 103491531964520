.max-width-tooltip .react-tooltip-lite,
.rfq-filters-tooltip .react-tooltip-lite {
  max-width: 500px !important;
  white-space: pre-wrap !important;
  font-size: 14px !important;
  line-height: 24px !important;
  word-break: break-word;
}

.pcd-tooltip .react-tooltip-lite {
  min-width: 250px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.po-details-status-pills .react-tooltip-lite {
  padding: 0 !important;
  border: 0;
}

.react-tooltip-lite {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  background-color: #333;
  color: var(--white);
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
  border: 1px solid #333;
  border-radius: 6px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  white-space: nowrap;
}

.ebony-tooltip .react-tooltip-lite {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ebony);
  color: var(--white);
  box-shadow: 0px 4px 6px -2px rgba(12, 16, 33, 0.03),
    0px 12px 16px -4px rgba(12, 16, 33, 0.08);
  border-radius: 8px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  white-space: break-spaces;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.tooltip-white {
  .react-tooltip-lite {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
    min-width: 50px;
    margin: 0 auto;
    background-color: var(--white);
    color: var(--rhino);
    box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
    border-color: var(--white);
    border-radius: 6px;
    font-family: 'Montserrat-Regular';
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    white-space: nowrap;
  }

  .react-tooltip-lite-arrow {
    border-color: var(--white);
  }
  &.width100 {
    width: 100%;
  }
}

.tooltip-for-popup {
  .react-tooltip-lite {
    width: 95px;
    font-size: 10px;
    font-weight: 500;
  }
  &.checkbox-tooltip .react-tooltip-lite {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
}

.tooltip-bold-blue {
  .react-tooltip-lite {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    margin: 0 auto;
    background-color: var(--rhino);
    color: var(--white);
    border-color: var(--rhino);
    border-radius: 6px;
    box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
    font-family: 'Montserrat-Regular';
    font-size: 13px;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    white-space: nowrap;
  }
  .react-tooltip-lite-arrow {
    border-color: var(--rhino);
  }
}

.tooltip-azure-radiance {
  .react-tooltip-lite {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: var(--azure-radiance);
    color: var(--white);
    border-color: var(--azure-radiance);
    border-radius: 6px;
    box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
    font-family: 'Montserrat-Regular';
    font-size: 13px;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    white-space: initial;
    inline-size: 500px;
    overflow-wrap: break-word;
  }
  .react-tooltip-lite-arrow {
    border-color: var(--azure-radiance);
  }
}

.tooltip-for-rev-table span.react-tooltip-lite {
  width: 220px;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
}

.info-tooltip .react-tooltip-lite {
  width: fit-content !important;
  font-weight: 600;
  font-size: 10px;
  color: var(--white);
  background-color: var(--rhino);
  padding: 8px !important;
  border-radius: 4px;
  font-family: Montserrat;
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
}

.tooltip-without-padding .react-tooltip-lite {
  padding: 0 !important;
  border-radius: 8px;
  border: none !important;
}

.tooltip-with-dark-arrow .react-tooltip-lite-arrow {
  border-color: var(--port-gore);
}

.tooltip-with-darker-arrow .react-tooltip-lite-arrow {
  border-color: var(--ebony);
}

.digital-pattern-info-tooltip > .react-tooltip-lite {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  max-width: 200px !important;
  white-space: normal;
  background-color: var(--ebony);
}

.gantt-chart-tna-tooltip .react-tooltip-lite {
  width: fit-content !important;
  font-weight: 600;
  font-size: 10px;
  color: var(--white);
  background-color: var(--rhino);
  padding: 4px 10px !important;
  border-radius: 4px;
  font-family: Montserrat;
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
}

.rfq_table_sampling_status > .react-tooltip-lite {
  white-space: pre;
}

.react-remark-tooltip > .react-tooltip-lite {
  max-width: 240px !important;
  overflow: auto;
  font-family: 'Montserrat';
}

.tooltip-transparent .react-tooltip-lite {
  display: flex !important;
  width: auto !important;
  padding: 0 !important;
  border: none;
  min-width: 0;
  background-color: transparent;
  color: var(--rhino);
  box-shadow: none;
  white-space: initial;
}

.tooltip-bold-rhino {
  .react-tooltip-lite {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    margin: 0 auto;
    background-color: var(--rhino);
    color: var(--white);
    border-color: var(--rhino);
    border-radius: 6px;
    box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
    font-family: 'Montserrat-Regular';
    font-size: 13px;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    white-space: pre-line;
    width: 245px;
    margin-top: 25px;
    z-index: 99;
  }
  .react-tooltip-lite-arrow {
    border-color: var(--rhino);
    margin-top: 25px;
  }
}

.tooltip-bold-rhino-info {
  @extend .tooltip-bold-rhino;
  .react-tooltip-lite {
    margin-top: 0;
  }
  .react-tooltip-lite-arrow {
    border-color: var(--rhino);
    margin-top: 0;
  }
}
.tooltip-bold-rhino-info-position {
  @extend .tooltip-bold-rhino;
  .react-tooltip-lite {
    margin-top: -40px;
  }
  .react-tooltip-lite-arrow {
    border-color: var(--rhino);
    margin-top: -40px;
    margin-left: -60px;
  }
}

.hover-dropdown-tooltip .react-tooltip-lite {
  padding: 0 !important;
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
}

.po-table-status-tooltip {
  .react-tooltip-lite {
    border: 1px solid;
    border-color: var(--white);
    background-color: var(--white);

    .tooltip-content-wrapper {
      background-color: var(--white);
      padding: 8px 16px;
      width: 256px;
      color: var(--rhino);
      font-size: 10px;

      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      row-gap: 4px;
      justify-items: self-start;
      align-items: baseline;

      .heading {
        font-weight: 600;
        line-height: 12px;
      }

      .description {
        font-weight: 400;
        line-height: 22px;
        white-space: break-spaces;
        text-transform: capitalize;
      }
    }
  }

  .react-tooltip-lite-arrow {
    border-color: var(--white);
  }
}

.po-details-hold-banner-tooltip .react-tooltip-lite {
  width: 400px !important;
  white-space: break-spaces;
}

.tooltip-white-position {
  @extend .tooltip-white;
  .react-tooltip-lite {
    margin-left: -28px;
  }
  .react-tooltip-lite-arrow {
    margin-top: 0;
    margin-left: -28px;
  }
}
