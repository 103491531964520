.body-container {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .body-container {
    max-width: 720px;
  }
}

@media screen and (min-width: 768px) {
  .body-container {
    max-width: 960px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 992px) {
  .body-container {
    max-width: 1140px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .body-container {
    max-width: 1368px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
