@import '_fadeInLeft';
@import '_fadeInRight';
@import '_fadeInDown';
@import '_fadeOutDown';
@import '_sidebar';

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    background-color: var(--wash-me);
  }
  100% {
    background-color: rgba(232, 234, 239, 0.7);
  }
}

// Ref: https://web.dev/prefers-reduced-motion
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

.no-transition {
  transition: none !important;
}

// defines the base animation for `animate__animated` class styles
.animate__animated {
  animation-duration: 1s;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.infinite-loader {
  animation: rotate360 1s linear infinite;
}
