.rfq-actions-portal {
  width: 300px;
  padding: 12px 0 8px !important;
  .toggleDropdownControl-section-header {
    padding: 8px 16px !important;
  }
  .toggleDropdownControl-list-item {
    font-weight: 400 !important;
    line-height: 24px !important;
    padding: 8px 16px !important;
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .rfq-actions-icon-wrapper {
      margin-right: 8px;
      width: 18px;
    }
  }
}
