@mixin small-viewport {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin medium-viewport {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin large-viewport {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin extra-large-viewport {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin mobile-potrait-viewport {
  @media only screen and (max-width: 480px) {
    @content;
  }
}
